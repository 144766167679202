export interface NavbarItem {
  url?: string;
  label: string;
  exact?: boolean;
  profiles?: string[];
}

export const MENUS_NAVBAR: NavbarItem[] = [
  {
    url: '',
    label: 'Início',
    exact: true,
  },
  {
    url: '/escolas',
    label: 'Escolas',
    profiles: ['Administrador', 'GRE', 'SEECT']
  },
  {
    url: '/escolas',
    label: 'Minha Escola',
    profiles: ['Gestor escolar']
  },
  {
    url: '/meus-alunos',
    label: 'Meus Alunos',
    profiles: ['Administrador', 'GRE', 'SEECT', 'Gestor escolar']
  },
  {
    url: '/meus-estudantes',
    label: 'Meus Estudantes',
    profiles: ['Administrador', 'GRE', 'Gestor escolar']
  },
  {
    url: '/status-desempenho-final',
    label: 'Desempenho Final',
    profiles: ['Gestor escolar']
  },
  {
    url: '/curriculo',
    label: 'Currículo',
    profiles: ['Gestor escolar']
  },
  {
    url: '/minhas-turmas',
    label: 'Minhas Turmas',
    profiles: [ 'Gestor escolar', 'GRE', 'ADM' ]
  },
  {
    url: '/pre-matricula',
    label: 'Pré-Matrícula',
    profiles: [ 'Gestor escolar' ]
  },
  {
    url: '/matricula',
    label: 'Matrícula',
    profiles: [ 'Gestor escolar' ]
  },
  {
    url: '/formacao-turmas',
    label: 'Processo de Matrículas',
  },
  {
    url: '/painel-monitoramento',
    label: 'Painel de Monit.',
    profiles: ['Administrador', 'GRE', 'SEECT']
  },
  {
    url: '/quadro-funcional',
    label: 'Quadro Funcional',
    profiles: [ 'Administrador', 'GRE', 'SEECT', 'Gestor escolar' ]
  },
  {
    url: '/calendario',
    label: 'Calendário',
    profiles: ['Gestor escolar']
  },
  {
    url: '/configuracao',
    label: 'Configurações',
    profiles: ['Administrador', 'SEECT']
  },
  {
    url: '/ajuda',
    label: 'Ajuda',
  },
  {
    url: '/enem',
    label: 'ENEM',
    profiles: ['Administrador', 'GRE', 'Gestor escolar']
  },
  {
    url: '/meus-dados',
    label: 'Meus Dados',
    profiles: ['Gestor escolar']
  },
];
