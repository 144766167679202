import { AfterViewInit, Directive, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { AbstractControl, FormControlName, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, fromEvent, merge } from 'rxjs';
import { Util } from 'src/app/shared/utils/util';
import { SubSink } from 'subsink';

import { ValidateMessage } from '../../core/models/validate-message.model';
import { ButtonSubmit } from './../../core/models/button-submit.model';
import { GenericValidatorForm } from './generic-validator-form';
import { SweetalertCustom } from './sweetalert-custom';
import { ValidationMessages } from './validation-messages';
import { ValidatorsCustom } from './validators-custom';

@Directive()
export class FormBase implements OnInit, AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements: ElementRef[];

  public nameScreen = '';
  public pageId = '';
  public typePage = '';
  public validateMessage = new ValidateMessage();
  form: FormGroup;
  public order: boolean = false;
  public collumnName: string;
  public buttonSubmit = new ButtonSubmit();
  private permissionUser;
  public sub = new SubSink();

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public validateMessages?: any,
    public permissionsService?: NgxPermissionsService
  ) {
    this.getParamsScreen();
  }

  ngOnInit() { }

  public setPermissionInComponent(
    permissions: string[],
    permissionUser: string,
    redirect = false,
    cadEdit = false
  ) {
    this.permissionUser = permissionUser;
    const existList = permissions.find((x) => x.includes(permissionUser));

    if (!existList && redirect) {
      return this.router.navigate(['/sem-permissao']);
    }

    this.permissionsService.loadPermissions(permissions);
  }

  public hiddenComponenteWithAll(permissions: string[]) {
    const existList = permissions.find((x) => x.includes(this.permissionUser));

    if (!existList) {
      return false;
    }

    return true;
  }

  ngAfterViewInit(): void {
    this.controlsBlurValidate();
    this.createValidateFields();
  }

  get isView() {
    return this.router.url.includes('visualizar');
  }

  get isEdit() {
    return this.router.url.includes('editar');
  }

  /**
   * Função que obtem a ação a ser executada
   */
  getParamsScreen() {
    this.pageId = this.activatedRoute.snapshot.params.id;
    this.nameScreen = Util.getScreenName(this.pageId);
  }

  /**
   * Função que realiza a validação por Blur
   */
  controlsBlurValidate() {
    const controlBlurs: Observable<any>[] = this.formInputElements.map(
      (formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur')
    );
    merge(...controlBlurs).subscribe((value) => {
      this.validateMessage.messageDisplay =
        this.validateMessage.genericValidator.processMessages(this.form);
    });
  }

  /**
   * Seta a classe de erro no campo
   * @param field Campo a ser realizado a tratativa
   */
  public setErrorValidate(field) {
    return Util.setErrorsValidate(
      this.form,
      this.validateMessage.messageDisplay,
      field,
    );
  }

  /**
   * Função que habilita/desabilita o botão de salvar
   * verificando ser o form é valido
   */
  public enableShipping() {
    return this.form.valid;
  }

  /**
   * Utilizados no autocomplete
   */
  public updateErrors() {
    setTimeout(() => {
      this.validateMessage.messageDisplay =
        this.validateMessage.genericValidator.processMessages(this.form);
    }, 100);
  }

  /**
   * Mensagens utilizadas na validação
   */
  public createValidateFields() {
    const globalValidateMessages = new ValidationMessages().getMessages();
    this.validateMessage.validationMessages = this.validateMessages
      ? { ...globalValidateMessages, ...this.validateMessages }
      : globalValidateMessages;

    this.validateMessage.genericValidator = new GenericValidatorForm(
      this.validateMessage.validationMessages
    );
  }

  /**
   * Função que força o processamento das mensagens vindo do input child
   */
  forceProcessMessages() {
    this.validateMessage.messageDisplay =
      this.validateMessage.genericValidator.processMessages(this.form);
  }

  /**
   * Função que desabilita o form quando a página é de visualização
   */
  public choosePageUpdateOrView(): void {
    if (this.router.url.includes('visualizar')) {
      this.form.disable();
    }
  }

  /**
   * Função que retorna classe de validação a partir de um Form
   */
  public getClassFormValid(form: AbstractControl) {
    if (form.touched) {
      return form.valid ? '' : 'is-invalid'
    }
    return '';
  }

  get pathBack() {
    const splitedPath = this.router.url.split('/');
    const indexAtRemove = this.pageId ? -2 : -1;
    return splitedPath.slice(0, indexAtRemove).join('/');
  }

  public isRequired(controlName: string, formGroup = this.form) {
    const control = formGroup.get(controlName);
    return control?.validator && control.validator({} as AbstractControl) && control.validator({} as AbstractControl)?.hasOwnProperty('required');
  }

  public validateFormFieldsAndShowAlert(formGroup: any, create: any) {
    const alertMessage = 'Todos os campos obrigatórios precisam ser preenchidos corretamente.';
    if (!Util.validateAllFormFields(formGroup)) {
      SweetalertCustom.showAlertConfirm('warning', `Atenção`, alertMessage);
      return;
    }
    return create();
  }

  /**
   * Método que habilita ou desabilita os controles passados no array de controles.
   * @param controls Array de chaves do formulário para serem habilitadas ou desabilitadas.
   * @param enable Variável que recebe um booleano para habilitar (true) ou desabilitar (false) os controles passados.
   */
  public enableOrDisableControls(controls: string[], enable: boolean, eventEmit = true) {
    controls.forEach(controlKey => {
      if (!this.form.get(controlKey)[enable ? 'enabled' : 'disabled']) {
        this.form.get(controlKey)[enable ? 'enable' : 'disable']({ emitEvent: eventEmit });
      }
      if (this.isView) this.form.get(controlKey).disable();
    });
  }

  /**
 * Adiciona ou remove validadores personalizados aos controles de um formulário com base nas configurações fornecidas.
 * @param controls Um array de chaves que representam os controles do formulário aos quais os validadores serão aplicados.
 * @param add Um booleano que indica se os validadores devem ser adicionados (true) ou removidos (false).
 */
  addRemoveRequiredValidators(controls: string[], add: boolean) {
    controls.forEach(controlName => {
      const control = this.form.get(controlName);

      if (control) {
        const isArray = Array.isArray(control.value);
        const validatorsRequired = isArray ? [ValidatorsCustom.noWhitespaceValidator, ValidatorsCustom.arrayLength] : [ValidatorsCustom.noWhitespaceValidator];

        add ? control.setValidators(validatorsRequired) : control.clearValidators();
        control.updateValueAndValidity();
      }
    });
  }

  /**
   * Método que reseta os controles passados no array de controles.
   * @param controls Array de chaves do formulário que deseja limpar.
   */
  public clearControls(controls: string[]) {
    controls.forEach(controlKey => {
      if (this.form.get(controlKey)?.value) {
        this.form.get(controlKey)?.reset();
      }
      if (this.validateMessage.genericValidator) {
        this.forceProcessMessages();
      }
    })
  }

  markAsTouchedAndUpdate(formControlName: string) {
    this.form.get(formControlName).markAsTouched();
    this.updateErrors();
  }

  public setRequired(required, controlName, disabledAndClear = false, formGroup = this.form) {
    const control = formGroup.get(controlName);
    if (required) {
      control.enable();
      control.setValidators([ValidatorsCustom.noWhitespaceValidator]);
    } else {
      if (disabledAndClear) {
        control.disable();
        control.setValue(null);
      };
      control.setValidators([]);
    }
    control.updateValueAndValidity();
  }
}
