import { Util } from 'src/app/shared/utils/util';
import { EnumBase } from '../models/enum-base.model';

export class Tempo extends EnumBase {
  constructor() {
    super(Util.convertEnumToArray(TempoEnum));
  }
}

export enum TempoEnum {
  PRIMEIRO_TEMPO = '1º Tempo',
  SEGUNDO_TEMPO = '2º Tempo',
  TERCEIRO_TEMPO = '3º Tempo',
  QUARTO_TEMPO = '4º Tempo',
  QUINTO_TEMPO = '5º Tempo',
  SEXTO_TEMPO = '6º Tempo',
  SETIMO_TEMPO = '7º Tempo',
  OITAVO_TEMPO = '8º Tempo',
  NONO_TEMPO = '9º Tempo',
}
