import { Guid } from 'guid-typescript';

export class NotificacaoReadDto {

    notificacaoUsuarioId: Guid;
    visualizada: boolean;

    constructor(object?: any) {
      if (object) {
        this.notificacaoUsuarioId        = object.notificacaoUsuarioId;
        this.visualizada                 = object.visualizada;
      }
    }
  }
