interface MenuType {
  url?: string;
  label: string;
  exact?: boolean;
}

export const MENUS_INFOBAR: MenuType[] = [
  // {
  //   url: '',
  //   label: 'Administração',
  // },
  // {
  //   url: '#',
  //   label: 'Educacenso',
  // },
  // {
  //   url: '#',
  //   label: 'Saber',
  // },
  // {
  //   url: '#',
  //   label: 'Ajuda',
  // },
  // {
  //   url: '#',
  //   label: 'Novidades',
  // },

];
