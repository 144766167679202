import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
  })
  export class PageTitleService {
    private _showTitle: boolean = true;

    constructor() {}
  
    public get showTitle(): boolean {
      return this._showTitle;
    }
  
    public setShowTitle(showTitle: boolean): void {
      this._showTitle = showTitle;
    }
  }