import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoadingCustomService } from './loading-custom.service';

@Injectable()
export class LoadingCustomInterceptor implements HttpInterceptor {

  constructor(
    private loadingService: LoadingCustomService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Ativar o loading
    if (!request.urlWithParams.includes('loading=false')) {
      this.loadingService.show();
    }

    return next.handle(request).pipe(
      tap(
        event => this.handleResponse(request, event),
        error => this.handleError(request, error)
      )
    );
  }

  handleResponse(req: HttpRequest<any>, event) {
    if (event instanceof HttpResponse) {
      // Desativa o Loading
      this.loadingService.hidden();
    }
  }

  handleError(req: HttpRequest<any>, event) {
    // Desativa o Loading
    this.loadingService.hidden();

    // Caso ele perca a autenticação
    if (event instanceof HttpErrorResponse) {
      if (event.status === 401) {
        this.router.navigate(['/auth/logout']);
      }
    }
  }

}
