import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public baseUrl = environment.apiUrl;
  public namespaceProject = environment.namespaceProject;

  constructor(public httpClient: HttpClient) {}

  public HeaderOptions(): any {
    // tratar caso tenha o token
    const TOKEN = localStorage.getItem(`${this.namespaceProject}.token`)
      ? 'Bearer ' + localStorage.getItem(`${this.namespaceProject}.token`)
      : '';

    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: TOKEN,
      }),
      observe: 'response' as 'response',
    };
  }

  private HeaderOptionsSpreadsheet(): any {
    // tratar caso tenha o token
    const TOKEN = localStorage.getItem(`${this.namespaceProject}.token`)
      ? 'Bearer ' + localStorage.getItem(`${this.namespaceProject}.token`)
      : '';
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: TOKEN,
      }),
      responseType: 'blob' as 'blob',
      observe: 'response' as 'response',
    };
  }

  public headerFileOptions(): any {
    // tratar caso tenha o token
    const TOKEN = localStorage.getItem(`${this.namespaceProject}.token`)
      ? 'Bearer ' + localStorage.getItem(`${this.namespaceProject}.token`)
      : '';

    return {
      headers: new HttpHeaders({
        responseType: 'blob',
        Authorization: TOKEN,
      }),
      observe: 'response' as 'response',
    };
  }

  /**
   * Generic GET Method
   * @param path URL path
   */
  public get(path: string): Observable<any> {
    return this.httpClient
      .get(this.baseUrl + path, this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  /**
   * Generic GET Method
   * @param path URL path
   */
  public getFile(path: string): Observable<any> {
    return this.httpClient
      .get(this.baseUrl + path, this.headerFileOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  /**
   * Generic POST Method
   * @param path URL path
   * @param body Request body
   */
  public post(path: string, body: any): Observable<any> {
    return this.httpClient
      .post(
        this.baseUrl + path,
        JSON.stringify(this.getClearObject(body)),
        this.HeaderOptions()
      )
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

   /**
   * Generic POST Method
   * @param path URL path
   * @param body Request body
   */
   public postV2(path: string, body: any): Observable<any> {
    return this.httpClient
      .post(
        this.baseUrl + path,
        JSON.stringify(body),
        this.HeaderOptions()
      )
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  /**
   * Generic PUT Method
   * @param path URL path
   * @param body Request body
   */
  public put(path: string, body: any): Observable<any> {
    return this.httpClient
      .put(this.baseUrl + path, JSON.stringify(body), this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  /**
   * Generic PATCH Method
   * @param path URL path
   * @param body Request body
   */
  public patch(path: string, body: any): Observable<any> {
    return this.httpClient
      .patch(this.baseUrl + path, JSON.stringify(body), this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  /**
   * Generic DELETE Method
   * @param path URL path
   */
  public delete(path: string): Observable<any> {
    return this.httpClient
      .delete(this.baseUrl + path, this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  public getDataSheet(path: string): Observable<any> {
    return this.httpClient
      .get(this.baseUrl + path, this.HeaderOptionsSpreadsheet())
      .pipe(catchError((e: any) => throwError(e)));
  }

  private getClearObject(object: any) {
    const clearObject = {};
    Object.keys(object).forEach((key) => {
      const value = object[key];
      if (typeof value === 'string' && !value.trim()) {
        return;
      }
      clearObject[key] = value;
    });
    return clearObject;
  }

  public getForSelect(path: string, params?: URLSearchParams): Observable<any> {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpClient.get(
      this.baseUrl + path + filters,
      this.HeaderOptions()
    );
  }

  public getQueryParamsForFilter(obj: object = {}) {
    const params = new URLSearchParams();
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (Array.isArray(value)) {
        value.forEach((itemOfArray) => {
          params.append(key, itemOfArray);
        });
      }else if (typeof value === 'boolean' || typeof value === 'number' || value) {
        params.set(key, obj[key]);
      }
    });
    return params;
  }
}
