import { ValidateMessages } from 'src/app/core/interfaces/validate-messages';

export class ValidationMessages implements ValidateMessages {

  messages = {
    login: {
      required: 'Login é obrigatório.',
      cpfInvalid: 'Login é inválido.'
    },
    password: {
      required: 'Senha é obrigatória.',
    },
    senhaAtual: {
      required: 'A Senha Atual é obrigatória.'
    },
    novaSenha: {
      required: 'Senha é obrigatória.',
      passwordNotMatch: 'Senhas não se correspondem.',
      passwordInvalid: 'A senha precisa ter pelo menos um número, um caractere e no mínimo 6 caracteres.'
    },
    novaSenhaRepeat: {
      required: 'Confirmar Senha é obrigatória.',
      passwordNotMatch: 'Senhas não se correspondem.',
      passwordInvalid: 'A senha precisa ter pelo menos um número, um caractere e no mínimo 6 caracteres.'
    },
    name: {
      required: 'Nome é obrigatório.',
    },
    nome: {
      required: 'Nome é obrigatório.',
    },
    cpf: {
      required: 'CPF é obrigatório.',
      cpfInvalid: 'CPF inválido.'
    },
    descricao: {
      required: 'Descrição é obrigatória.',
    },
    email: {
      required: 'E-mail é obrigatório.',
      emailInvalid: 'E-mail é inválido.'
    },
    sobreNome: {
      required: 'Sobrenome é obrigatório.',
    },
    telefone: {
      required: 'Telefone é obrigatório.',
      phoneInvalid: 'Telefone é inválido.'
    },
    perfilId: {
      required: 'Perfil é obrigatório.',
    },
    departamentoId: {
      required: 'Departamento é obrigatório.',
    },
    observacoes: {
      required: 'Observações é obrigatório.',
    },
    obrigacoesLeis: {
      required: 'Observações e Leis é obrigatório.',
      maxLength: 'Apenas 1000 caracteres são permitidos.'
    },
    salaId: {
      required: 'Sala é obrigatória.',
    },
    temporalidadeId: {
      required: 'Temporalidade é obrigatória.',
    },
    turma: {
      required: 'Turma é obrigatória.',
    },
    anoLetivo: {
      required: 'Ano Letivo é obrigatório.'
    },
    turmaId: {
      required: 'Turma é obrigatória.',
    },
    turnoId: {
      required: 'Turno é obrigatório.',
    },
    propostaVagas: {
      required: 'Proposta de vagas é obrigatório.',
      minValueFieldPropostaVagas: 'O Valor não pode ser inferior ao contido em Sugestão de Vagas'
    },
  };

  getMessages() {
    return this.messages;
  }
}
