import { EnumBaseType } from 'src/app/shared/types/enum-base.type';

export class EnumBase {

    private enum: EnumBaseType[];

    constructor(enumParam: EnumBaseType[]) {
        this.enum = enumParam;
    }

   public getAll(): EnumBaseType[] {
        return this.enum;
    }

    public getAllForSelect(): EnumBaseType[] {
        const list = [...this.enum];
        list.unshift(({ name: 'Selecione', value: '' }));
        return list;
    }
    public getAllForSelectStatus(): EnumBaseType[] {
        const list = [...this.enum];
        list.unshift(({ name: 'Todos', value: '' }));
        return list;
    }

    public getById(value: string| boolean): EnumBaseType {
        return this.enum.find((item: EnumBaseType) => item.value === value);
    }

    public getValue(name: string) {
        const result = this.enum.find((item: EnumBaseType) => item.name === name);
        return result ? result.value : null;
    }

    public getName(value: string) {
        const result = this.enum.find((item: EnumBaseType) => item.value === value);
        return result ? result.name : null;
    }

    public getColorByName(name: string) {
        const result = this.enum.find((item: EnumBaseType) => item.name === name);
        return result ? result.color : null;
    }

    public getColorById(value: string) {
        const result = this.enum.find((item: EnumBaseType) => item.value === value);
        return result ? result.color : null;
    }

    public getByValueForSelect(value: string) {
        const list = [...this.enum];
        const listSelect = list.filter( el => {
            return el.value.toString().indexOf(value) > -1;
        });
        listSelect.unshift(({ name: 'Selecione', value: '' }));
        return listSelect;
    }

    public getByValueStatusParecer(value: string) {
      const list = [...this.enum];
      const listSelect = list.filter( el => {
        return el.value.toString().indexOf(value) > -1;
      });
      return listSelect;
    }

    public getByValueForEnumSelect(value: string) {
        const list = [...this.enum];
        const listSelect = list.filter( el => {
            return el.value.toString().indexOf(value) > -1;
        });
        return listSelect;
    }
}
