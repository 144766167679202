<div class="brand-image">
    <p><i class="fas fa-compress"></i> SIAGE</p>
</div>
<div class="access-dinied">
    <p class="icon"><i class="fa fa-lock"></i></p>
    <h2 class="title">Acesso Negado...</h2>
    <p class="description">Você não tem permissão para acessar essa Página.</p>
    <p class="code">403</p>
</div>

