import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const NAMES_PROJECT  = environment.namespaceProject;
@Injectable({
  providedIn: 'root'
})

export class AllDatasGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // Verificar se o usuário já selecionou o ano letivo na aplicação
    const anoLetivo = localStorage.getItem(`${NAMES_PROJECT}.anoLetivo`);

    if (anoLetivo === null) {
      this.router.navigate(['/auth/selecionar-ano-letivo']);
      return false;
    }

    return true;
  }



}
