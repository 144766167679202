import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

export interface DownloadEvent {
  title: string;
  loading: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class RelatorioService {
  public static downloadEvent$ = new EventEmitter<DownloadEvent>();
  private controller = 'Relatorio';
  public baseUrl = environment.apiUrl;
  public namespaceProject = environment.namespaceProject;

  constructor(
    private httpService: HttpClient,
    public apiService: ApiService
  ) { }

  headerFileOptions(): any {
    // tratar caso tenha o token
    const TOKEN = localStorage.getItem(`${this.namespaceProject}.token`) ? 'Bearer ' + localStorage.getItem(`${this.namespaceProject}.token`) : '';

    return {
      headers: new HttpHeaders({
        Authorization: TOKEN,
      }),
      responseType: 'blob',
      observe: 'response',
    };
  }

  getRelatorioFinal() {
    return this.httpService.get(`${this.baseUrl}${this.controller}/final`,
      this.headerFileOptions());
  }


  getRelatorioAlunosMatriculadosAnalitico(filter: any) {
    RelatorioService.downloadEvent$.emit({
      title: 'Download do relatório em andamento.',
      loading: true
    });
    const filters = `?loading=false&${filter || ''}`;
    return this.httpService.get(`${this.baseUrl}${this.controller}/alunos-matriculados-analitico/${filters}`,
      this.headerFileOptions())
      .pipe(
        tap(() => RelatorioService.downloadEvent$.emit({ title: '', loading: false })),
        catchError((e: HttpErrorResponse) => throwError(e))
      );
  }

  getRelatorioRemanejamentoInterno(anoLetivoId: any) {
    return this.httpService.get(`${this.baseUrl}${this.controller}/remanejamento-interno/${anoLetivoId}`,
      this.headerFileOptions());
  }

  getRelatorioRemanejamentoExternoNovato(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}${this.controller}/remanejamento-externo-novato${filters}`,
      this.headerFileOptions());
  }

  getEstudanteEnemDocumentacaoPendente(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}EnemEstudante/export/documentacao/excel${filters}`,
      this.headerFileOptions());
  }

  getRelatorioMatriculaNaoEfetivada(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}${this.controller}/matricula-nao-efetivada${filters}`,
      this.headerFileOptions());
  }

  getEstudanteEnemTaxaInscricao(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}EnemEstudante/export/inscricao/excel${filters}`,
      this.headerFileOptions());
  }

  getEstudanteEnemUnificadoEtapasIniciais(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}EnemEstudante/export/etapa-inicial/excel${filters}`,
      this.headerFileOptions());
  }

  getEstudanteEnemTaxaIsencao(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}EnemEstudante/export/isencao/excel${filters}`,
      this.headerFileOptions());
  }

  getEstudanteEnemPontuacaoRedacao(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}EnemEstudante/export/pontuacao-redacao/excel${filters}`,
      this.headerFileOptions());
  }
  getEstudanteEnemPontuacaoAreaConhecimento(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}EnemEstudante/export/media-por-nota/excel${filters}`,
      this.headerFileOptions());
  }

  getEstudanteEnemUnificadoPresenca(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}EnemEstudante/export/unificado-presenca/excel${filters}`,
      this.headerFileOptions());
  }

  getEstudanteEnemUnificadoPontuacao(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}EnemEstudante/export/unificado-pontuacao/excel${filters}`,
      this.headerFileOptions());
  }

  getXLSPresencaPorDiaDeProva(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}EnemEstudante/export/presenca/excel${filters}`,
      this.headerFileOptions());
  }
  getXLSRelatorioMediasPontuacaoPorGre(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}EnemEstudante/export/media-por-gre/excel${filters}`,
      this.headerFileOptions());
  }
  getXLSRelatorioTaxaDeComparecimentoCompleto(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}EnemEstudante/relatorio-enem-comparecimento-excel${filters}`,
      this.headerFileOptions());
  }
}
