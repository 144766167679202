import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Util } from 'src/app/shared/utils/util';

@Injectable({
  providedIn: 'root'
})
export class ControlAccessGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const usuario = Util.getUsuarioSession();
    this.isIsFirsAccess(usuario);
    return true;
  }

  public isIsFirsAccess(usuario): boolean {
    if (usuario !== null && usuario.primeiroAcesso) {
      this.router.navigate(['/auth/primeiro-acesso']);
      return false;
    }
  }

}
