import { Util } from 'src/app/shared/utils/util';
import { EnumBase } from '../models/enum-base.model';

export class DiaSemanaUtil extends EnumBase {
  constructor() {
    super(Util.convertEnumToArray(DiaSemanaUtilEnum));
  }
}

export enum DiaSemanaUtilEnum {
  SEGUNDA = 'Segunda - Feira',
  TERCA = 'Terça - Feira',
  QUARTA = 'Quarta - Feira',
  QUINTA = 'Quinta - Feira',
  SEXTA = 'Sexta - Feira',
}
