import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Util } from 'src/app/shared/utils/util';
import { environment } from 'src/environments/environment';

const NAMES_PROJECT  = environment.namespaceProject;

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // Verificar se o usuário está logado na aplicação
    const token = localStorage.getItem(`${NAMES_PROJECT}.token`);

    if (token === null) {
      this.router.navigate(['/auth/']);
      return false;
    }

    const usuario = Util.getUsuarioSession();
    const profiles = this.getProfilesRouteOrFirstParentRoute(next);

    if (profiles.length) {
      return this.validateProfilePermission(profiles, usuario);
    }

    return true;
  }

  public isIncompleteRegistration(usuario): boolean {
    if (usuario !== null && !usuario.cadastroCompleto) {
      this.router.navigate(['/cadastro-incompleto']);
      return false;
    }
  }

  public validateProfilePermission(profiles, user) {
    if (profiles.includes(user.perfil?.toLowerCase())) {
      return true;
    } else {
      this.router.navigate(['/sem-permissao']);
      return false;
    }
  }

  /**
   * Obtém um array de perfis associados a uma rota específica ou à primeira rota pai que possui perfis.
   * 
   * @param {ActivatedRouteSnapshot} route - O snapshot da rota atual.
   * @returns {string[]} Um array de strings representando os perfis associados.
   * 
   * Se a rota atual tem dados de perfil, esses perfis são retornados. Caso contrário, a função verifica recursivamente nas rotas pai,
   * até encontrar uma rota com perfis ou até que não haja mais rotas pai. Isso é útil para lidar com casos em que os perfis
   * podem estar definidos em um nível superior na hierarquia de rotas.
   */

  private getProfilesRouteOrFirstParentRoute(route: ActivatedRouteSnapshot): string[] {
    const profiles = [];

    if (route.data?.profiles) {
      profiles.push(...route.data.profiles);
    } else if (route.parent && !profiles.length) {
      profiles.push(...this.getProfilesRouteOrFirstParentRoute(route.parent));
    }

    return profiles.map((x: string) => x?.toLowerCase());
  }
}
