import { DadosPeriodoAprovacaoDto } from '../periodo-aprovacao/dados-periodo-aprovacao.dto';
import { DadosUsuarioLoginDTO } from './dados-usuario-login.dto';

export class UsuarioLoginDTO {

  dadosUsuario: DadosUsuarioLoginDTO;
  dadosPeriodoAprovacao: DadosPeriodoAprovacaoDto;

  token: string;
  message: string;

  constructor(object?: any) {
    if (object) {
      this.dadosUsuario = object.dadosUsuario;
      this.dadosPeriodoAprovacao = object.dadosPeriodoAprovacao;
      this.token = object.token;
      this.message = object.message;
    }
  }
}
