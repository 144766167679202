<div class="info-bar">
  <div class="container">
    <div class="row justify-content-end align-items-center">
      <div class="mr-auto">
        <a
          [routerLink]="['/auth/selecionar-ano-letivo']"
          class="nav-link mouse-hover-pointer"
          title="Selecionar Ano Letivo"
        >
          <h6 class="mb-0 text-white">Ano Letivo {{anoLetivo}}</h6>
        </a>
        <h6 class="info-teste" *ngIf="ambienteQA">
          {{usuarioSession.nome}}, você está no ambiente de HOMOLOGAÇÃO/TREINAMENTO.
        </h6>
      </div>

      <ul class="nav-info">
        <li *ngFor="let menu of menusInfoBar">
          <a
            class="nav-item"
            [title]="menu.label"
            [routerLink]="menu.url"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: menu.exact }"
          >
            {{ menu.label }}
          </a>
        </li>

        <!-- Notification -->
        <li class="dropdown user-menu">
          <a class="nav-item px-2 py-2 notification" data-toggle="dropdown" href="#" title="Notificações">
            <em class="far fa-bell"  style="font-size: 1.5rem;"></em>
          </a>
          <ul
            class="dropdown-menu dropdown-menu-lg dropdown-menu-right list-group-flush"
            style="max-height: 276px; overflow: auto"
          >
            <span class="dropdown-item dropdown-header border-bottom"
              >{{ notificacoes.length }} Notificações</span
            >
            <li
              class="list-group-item list-group-item-action"
              (click)="readNotification(item)"
              *ngFor="let item of notificacoes"
            >
              <div class="row pl-1 pr-1">
                <div class="col-md-12 p-0 text-truncate mouse-hover-custom">
                  <span class="message-notification text-sm">
                    <i class="fas fa-question-circle mr-1"></i>
                    {{ item.mensagem }}
                  </span>
                </div>
                <div class="col-md-12 pr-0">
                  <span class="float-right text-muted text-sm">{{
                    item.dataCriacao | date: "dd/MM/yyyy HH:mm"
                  }}</span>
                </div>
              </div>
            </li>
            <a class="nav-link p-0 mouse-hover-pointer">
              <span
                class="dropdown-item dropdown-footer"
                data-toggle="modal"
                data-target="#listarNotificacoesModal"
                (click)="readNotifications()"
              >
                Ver todas notificações.
              </span>
            </a>
          </ul>
        </li>
        <!-- End Notification -->

        <!-- User -->
        <!-- <li class="dropdown user-menu"> -->
        <li class="user-menu">
          <a class="infobar-icon nav-item px-2 py-1" data-toggle="dropdown" href="#" title="Área do Usuário">
            <em class="far fa-user-circle" style="font-size: 1.9rem;"></em>
          </a>
          <!-- <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <li class="user-header bg-primary">
              <img
                alt="User Image"
                class="img-circle elevation-2"
                src="assets/img/user-128x128.jpg"
              />
              <p>
                {{ usuarioSession?.nome
                }}<small *ngFor="let item of usuarioSession?.perfis">
                  {{ item.descricao }}</small
                >
              </p>
            </li>
            <li class="user-footer">
              <a
                class="btn btn-default btn-flat float-right"
                [routerLink]="['/auth/logout']"
                >Sair</a
              >
            </li>
          </ul> -->
        </li>
        <!-- End User -->


        <li>
          <a
            (click)="logout()"
            class="nav-item active"
            title="Sair"
            href="javascript:void(0);"
          >
            Sair
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<nav>
  <div class="container">
    <div class="">
      <a routerLink="/" class="col-auto logo-container">
        <img src="assets/img/siage_logo_01.png" alt="" srcset="" class="logo" />
      </a>

      <!-- Menu -->
      <ul class="nav-menu ml-auto flex-wrap">
        <li *ngFor="let menu of menusTratados">
          <a
            *ngIf="shouldShowNavbarItem(menu)"
            [title]="menu.label"
            class="nav-item"
            [routerLink]="menu.url"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: menu.exact }"
          >
            {{ menu.label }}
          </a>
        </li>
        <li *ngIf="downloadEvent?.loading">
          {{ downloadEvent.title }} <em class="fas fa-spinner pi-spin"></em>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- <nav class="main-header navbar navbar-expand navbar-custom navbar-dark">
  <ul class="navbar-nav menu-icon">
    <li class="nav-item" style="margin-top: -5px;">
      <a class="nav-link menu-item" data-widget="pushmenu" href="#">
        <div class="nav-icon open">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </a>
    </li>
  </ul>
  <ul  class="navbar-nav ml-auto">
    <li class="nav-item dropdown ml-2">
      <a class="nav-link btn-link-custom-secundary" [routerLink]="['/auth/logout']" title="sair" data-toggle="dropdown"
        href="#">
        <em class="fas fa-sign-out-alt text-white"></em>
      </a>
    </li>
  </ul>
</nav> -->
