import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SweetalertCustom } from 'src/app/shared/utils/sweetalert-custom';
import { Util } from 'src/app/shared/utils/util';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

import { UsuarioLoginDTO } from '../dtos/usuario/usuario-login.dto';
import { ButtonSubmit } from '../models/button-submit.model';
import { ErrorMessage } from '../models/error-message.model';

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private namespaceProject = environment.namespaceProject;

  constructor(private httpClient: HttpClient, private router: Router) {}

  /**
   * Obtem o token da sessão para utilizar nas requisições
   */
  public getOptions(): any {
    // tratar caso tenha o token
    const TOKEN = localStorage.getItem('seectpb.token')
      ? 'Bearer ' + localStorage.getItem('seectpb.token')
      : '';

    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: TOKEN,
      }),
      observe: 'response' as 'response',
    };
  }

  /**
   * Reliza a autenticação no sistema
   * @param errorMessage Mensagem de erro
   * @param obj Objeto a ser transmitido
   * @param buttonSubmit Botão de submissão
   */
  public login(
    errorMessage: ErrorMessage,
    obj: any,
    buttonSubmit?: ButtonSubmit
  ) {
    buttonSubmit.buttonText = 'ACESSANDO';
    buttonSubmit.buttonSubmited = true;

    this.httpClient
      .post(BASE_URL + 'usuario/login', JSON.stringify(obj), this.getOptions())
      .subscribe(
        (response: any) => {
          this.setValueButtonSubmeted(buttonSubmit);
          if (response.status === 201 || response.status === 200) {
            this.successSend(new UsuarioLoginDTO(response.body.data));
          }
        },
        (error: any) => {
          Util.clearErrorMessage(errorMessage);
          Util.setErrorMessage(errorMessage, error, 'warning');
          this.setValueButtonSubmeted(buttonSubmit);
        }
      );
  }

  /**
   * Seta as infomações do usuário e token do localStorage
   * @param response Response da requisição
   */
  public successSend(response: UsuarioLoginDTO): void {
    const usuario = response.dadosUsuario;
    const dadosPeriodoAprovacao = response.dadosPeriodoAprovacao;
    localStorage.setItem('seectpb.token', response.token);
    localStorage.setItem('seectpb.usuario', btoa(JSON.stringify(usuario)));
    localStorage.setItem('seectpb.dadosPeriodoAprovacao', btoa(JSON.stringify(dadosPeriodoAprovacao)));
    this.router.navigate(['/auth/selecionar-ano-letivo']);
  }

  /**
   * Seta o nome e habilita a ação de submissão
   * @param buttonSubmit Botão de submissão
   */
  public setValueButtonSubmeted(buttonSubmit): void {
    buttonSubmit.buttonText = 'ACESSAR';
    buttonSubmit.buttonSubmited = false;
  }

  /**
   * Função de alterar senha
   * @param errorMessage Menssagem de erro
   * @param obj Objeto da transmissão
   * @param buttonSubmit Botão de submissão
   */
  public changePassword(
    errorMessage: ErrorMessage,
    obj: any,
    buttonSubmit?: ButtonSubmit
  ): void {
    buttonSubmit.buttonText = 'ACESSANDO';
    buttonSubmit.buttonSubmited = true;

    this.httpClient
      .post(
        BASE_URL + 'usuario/change-password',
        JSON.stringify(obj),
        this.getOptions()
      )
      .subscribe(
        (response: any) => {
          this.setValueButtonSubmeted(buttonSubmit);
          if (response.status === 201 || response.status === 200) {
            this.successSendRedirectChanged(
              'Senha',
              'Sua senha foi alterada com sucesso, faça o login novamente para valida-la.'
            );
          }
        },
        (error: any) => {
          Util.clearErrorMessage(errorMessage);
          Util.setErrorMessage(errorMessage, error, 'warning');
          this.setValueButtonSubmeted(buttonSubmit);
        }
      );
  }

  /**
   * Função de redefinição de senha
   * @param errorMessage Menssagem de erro
   * @param obj Objeto de transmissão
   * @param buttonSubmit Botão de submissão
   */
  public redefinePassword(
    errorMessage: ErrorMessage,
    obj: any,
    buttonSubmit?: ButtonSubmit
  ): void {
    buttonSubmit.buttonText = 'REDEFININDO SENHA';
    buttonSubmit.buttonSubmited = true;

    this.httpClient
      .patch(
        BASE_URL + 'usuario/reset-password',
        JSON.stringify(obj),
        this.getOptions()
      )
      .subscribe(
        (response: any) => {
          this.setValueButtonSubmitedReset(buttonSubmit);
          if (response.status === 201 || response.status === 200) {
            this.successSendResetPassword();
          }
        },
        (error: any) => {
          Util.clearErrorMessage(errorMessage);
          Util.setErrorMessage(errorMessage, error, 'warning');
          this.setValueButtonSubmitedReset(buttonSubmit);
        }
      );
  }

  /**
   * Seta o nome e habilita a ação de submissão
   * @param buttonSubmit Botão de submissão
   */
  public setValueButtonSubmitedReset(buttonSubmit): void {
    buttonSubmit.buttonText = 'REDEFINIR SENHA';
    buttonSubmit.buttonSubmited = false;
  }

  /**
   * Função que exibe a mensagem de sucesso
   * @param name Nome(descrição) a ser exibida na mensagem
   * @param activeModal Variavel que ativa/desativa o modal
   */
  public successSendModal(name = '', activeModal?: NgbActiveModal): void {
    SweetalertCustom.showAlertTimer(
      'success',
      `${name} salvo(a) com sucesso!`
    ).then((result) => {
      if (result) {
        activeModal.close();
      }
    });
  }

  /**
   * Função que exibe a mensagem de sucesso e redireciona
   * @param name Nome(descrição) a ser exibida na mensagem
   */
  public successSendRedirect(name = ''): void {
    SweetalertCustom.showAlertTimer(
      'success',
      `${name} salva com sucesso!`
    ).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        this.router.navigate([`/auth/login`]);
      }
    });
  }

  public successSendRedirectChanged(name = '', description = ''): void {
    SweetalertCustom.showAlertConfirm(
      'success',
      `${name} salva com sucesso!`,
      description,
      'Ok'
    ).then(() => this.router.navigate([`/auth/logout`]));
  }

  public successSendResetPassword(): void {
    SweetalertCustom.showAlertConfirm(
      'success',
      `Senha resetada com sucesso!`,
      'Uma nova senha foi gerada e enviada para seu e-mail.',
      'Ok',
    ).then(() => this.router.navigate(['/']));
  }

  getCurrentEscolaId() {
    return JSON.parse(atob(localStorage.getItem(`${this.namespaceProject}.escolaId`)));
  }

  public sendAnoLetivo(anoLetivo: {ano: string, id: string}){
    localStorage.setItem(`${this.namespaceProject}.anoLetivo`, btoa(JSON.stringify(anoLetivo)));
    this.router.navigate(['/']);
  }

  public getAnoLetivo(){
    const anoLetivo = localStorage.getItem(`${this.namespaceProject}.anoLetivo`);
    return anoLetivo ? JSON.parse(atob(anoLetivo)) : null;
  }
}

