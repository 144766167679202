import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Route, Router, Routes } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { TipoPerfil } from 'src/app/core/enums/tipo-perfil.enum';
import { ErrorMessage } from 'src/app/core/models/error-message.model';
import { environment } from 'src/environments/environment';
import { EnumBaseType } from '../types/enum-base.type';
import { ValidateMessage } from 'src/app/core/models/validate-message.model';
import { GenericValidatorForm } from './generic-validator-form';
import { DiaSemanaUtil } from 'src/app/core/enums/dia-semana-util.enum';
import { Tempo } from 'src/app/core/enums/tempo.enum';
import { QuadroHorarioPdfDto } from 'src/app/core/dtos/quadro-horario/quadro-horario-pdf.dto';

const NAME_PROJECT = environment.namespaceProject;

export class Util {
  /**
   * Função para setar os errors da requisição conforme de acordo com os seus tipos
   * @param errorMessage Objeto utilizado para incluir os erros
   * @param error Objeto que vem o HttpErroResponse
   * @param typeErr String para setar qual o tipo de error ex: danger, success, warning, info
   * @param errorCustom Boolean para caso queira inserir uma mensagem sem ser pelo tipo do erro da requisição
   */
  static setErrorMessage(
    errorMessage: ErrorMessage,
    error,
    typeErr = 'danger',
    errorCustom = false
  ) {
    if (errorCustom) {
      errorMessage.errorType = typeErr;
      errorMessage.existError = true;
      errorMessage.errorList = this.setErrorList(error);
    }

    if (!errorCustom && error.status === 400) {
      errorMessage.errorType = typeErr;
      errorMessage.existError = true;
      errorMessage.errorList = this.setErrorList(error);
    }

    if (!errorCustom && error.status === 403) {
      errorMessage.errorType = 'warning';
      errorMessage.existError = true;
      errorMessage.errorList.push('Usuário não tem permissão de acesso!');
    }

    if ((!errorCustom && error.status === 0) || error.status === 404) {
      errorMessage.errorType = 'warning';
      errorMessage.existError = true;
      errorMessage.errorList.push(
        'Oopss ocorreu um erro ao processar o seu pedido, não conseguimos conectar com os nossos serviços, por favor tente novamente!'
      );
    }

    setTimeout(() => {
      this.clearErrorMessage(errorMessage);
    }, 10000);
  }

  static setErrorMessageCustom(
    errorMessage: ErrorMessage,
    error: any,
    typeErr = 'danger',
    timeoutClearMessage = 30000
  ) {
    errorMessage.errorType = typeErr;
    errorMessage.existError = true;
    errorMessage.errorList = this.setErrorListCustom(error);

    setTimeout(() => {
      this.clearErrorMessage(errorMessage);
    }, timeoutClearMessage);
  }

  /**
   * Função para Limpar Objeto utilizado para incluir os erros
   * @param errorMessage Objeto utilizado para incluir os erros
   */
  static clearErrorMessage(errorMessage: ErrorMessage) {
    errorMessage.errorType = '';
    errorMessage.existError = false;
    errorMessage.errorList = [];
  }

  /**
   * Função utilizada para setar os errors na lista de acordo com a requisição
   * @param error Objeto que vem o HttpErroResponse
   */
  static setErrorList(error) {
    let list = [];
    Array.isArray(error.error.errors)
      ? (list = error.error.errors)
      : list.push(error.error.errors);
    return list;
  }

  /**
   * Função utilizada para setar os errors na lista custumizada de acordo com a requisição
   * @param error Objeto que vem o HttpErroResponse
   */
  static setErrorListCustom(error) {
    let list = [];
    Array.isArray(error) ? (list = error) : list.push(error);
    return list;
  }

  /**
   * Função para alterar o valor do botão de Buscar
   * @param buttonSubmitConfig Objeto para configuração do botão
   * @param isResp Controle a submissão do botão
   */
  static setBtnFilterReq(buttonSubmitConfig?, isResp = false) {
    if (buttonSubmitConfig) {
      buttonSubmitConfig.buttonText = !isResp ? 'Buscando' : 'Buscar';
      buttonSubmitConfig.buttonSubmited = !isResp;
    }
  }

  /**
   * Função para alterar o valor do botão de Submissão
   * @param buttonSubmitConfig Objeto para configuração do submissão
   * * @param isResp Controle a submissão do botão
   */
  static setBtnSubmitReq(buttonSubmitConfig?, isResp = false) {
    if (buttonSubmitConfig) {
      buttonSubmitConfig.buttonText = !isResp ? 'Salvando' : 'Salvar';
      buttonSubmitConfig.buttonSubmited = !isResp;
    }
  }

  /**
   * Função para alterar o valor do botão de Submissão
   * @param buttonSubmitConfig Objeto para configuração do submissão
   * * @param isResp Controle a submissão do botão
   */
  static setBtnSubmitReqCustom(
    buttonSubmitConfig?,
    isResp = false,
    desc1 = '',
    desc2 = ''
  ) {
    if (buttonSubmitConfig) {
      buttonSubmitConfig.buttonText = !isResp ? desc2 : desc1;
      buttonSubmitConfig.buttonSubmited = !isResp;
    }
  }
  /**
   * Metodo para pegar o nome para a tela (cadastrar/editar)
   * @param id Identificador
   * @param detalhes Bolean para informar se a tela é de detalhes
   */
  static getScreenName(id?: string, detalhes = false) {
    return !id || !id.trim()
      ? 'ADICIONAR'
      : id && !detalhes
      ? 'EDITAR'
      : 'VISUALIZAR';
  }

  /**
   * Função para setar a classe de errro no campo
   * @param formGroup FormGroup do parametro
   * @param messageDisplay Mensagem a ser exibida
   * @param field Campo que receberá a mensagem de validação
   */
  static setErrorsValidate(
    formGroup: FormGroup,
    messageDisplay,
    field: string
  ) {
    if (messageDisplay[field]) {
      return 'is-invalid';
    }
  }

  /**
   * Função para preencher os valores pelo ID
   * @param resp variavel que vem os dados da requisição
   * @param formGroup variavel que traz o form group
   */
  static patchValueForm(obj: any, formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      if (obj[key] || typeof obj[key] === 'boolean') {
        try {
          formGroup.controls[key].patchValue(obj[key]);
        } catch (error) {}
      }
    });
  }

  static patchDateValueForm(date: Date, formControl: AbstractControl) {
    if (!date || !formControl) {
      return;
    }

    const formatedDate = moment(date).format('YYYY-MM-DD');
    formControl.patchValue(formatedDate);
  }

  /**
   * Retorna URLSearchParams
   */
  static createFilter() {
    const params = new URLSearchParams();
    return params;
  }

  /**
   * Retorna URLSearchParams com o parametro de "Ativo" com o valor "true"
   */
  static createFilterStatusActive() {
    const params = new URLSearchParams();
    params.append('ativo', 'true');
    return params;
  }

  /**
   * Remove os acentos da string
   * @param str string para ser removida os acentos
   */
  static removeAccents(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * Capitaliza a string, deixando a primeira letra maiuscula ( P/ uma palavra)
   * @param str string fonte: Ex.: caSA
   * @returns retorna a string final: Ex.: Casa
   */
  static toCapitalize(str: string) {
    const firstLetter = str.charAt(0).toUpperCase;
    const remaining = str.slice(1).toLowerCase();
    return firstLetter + remaining;
  }

  /**
   * Limpa todos os campo do form
   * @param formGroup O form a ser limpo os campos
   */
  static clearFields(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach((campo) => {
      const control = formGroup.get(campo);
      if (control instanceof FormGroup || control instanceof FormControl) {
        if (control instanceof FormGroup) {
          this.clearFields(control);
        } else {
          if (Array.isArray(control.value)) {
            control.setValue([]);
          } else {
            control.setValue('');
          }
        }
      } else if (control instanceof FormArray) {
        control.controls.splice(0);
        control.updateValueAndValidity();
      }
    });
    formGroup.updateValueAndValidity();
  }

  /**
   * Cria as query params do filtro de busca
   * @param param Objero a ser convertido em parametros
   */
  static getQueryParams(param: object) {
    const params: URLSearchParams = new URLSearchParams();

    Object.keys(param).forEach((campo) => {
      const item = param[campo];
      if (item) {
        params.append(campo, item);
      }
    });

    return params;
  }

  /**
   * Obtem os campos invalidos do form
   * @param form Form a ser verificado
   */
  static catchFieldsInvalids(form: FormGroup | FormArray): string[] {
    const invalidControls: string[] = [];

    const recursiveFunc = (formGP: FormGroup | FormArray) => {
      Object.keys(formGP.controls).forEach((field) => {
        const control = formGP.get(field);
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        } else {
          if (control.invalid) {
            invalidControls.push(field);
          }
        }
      });
    };
    recursiveFunc(form);
    return invalidControls;
  }

  /**
   * * Abre o modal no tamanho
   * @param modalService Serviço do modal
   * @param component O componente a ser aberto
   * @param size O tamanho do modal (lg, xl, sm) / Valor defaul md
   */
  static openModal(
    modalService: NgbModal,
    component: any,
    size: string = 'md'
  ) {
    const modalRef = modalService.open(component, {
      backdrop: 'static',
      size,
      keyboard: false,
      windowClass: 'modal-custom-' + size,
    });

    return modalRef;
  }

  /**
   * Convert o arquivo de base64 em BlobData
   * @param base64Data Arquivo em Base64
   * @param contentType Tipo do content type
   * @param sliceSize Tamanhp do arquivo
   */
  static convertBase64ToBlobData(
    base64Data: string,
    contentType: string = 'image/png',
    sliceSize = 512
  ) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  /**
   * Realiza o download do arquivo
   * @param file Arquivo para ser baixado
   */
  static downloadFile(file: any) {
    const blobData = Util.convertBase64ToBlobData(file.base64);

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobData, file.filename);
    } else {
      // chrome
      const blob = new Blob([blobData], { type: file.extension });
      const url = URL.createObjectURL(blob);
      const ext =
        file.extension.split('/').length > 0
          ? file.extension.split('/')[1]
          : '';
      if (
        ext === 'pdf' ||
        ext === 'PDF' ||
        ext === 'png' ||
        ext === 'PNG' ||
        ext === 'jpg' ||
        ext === 'JPG' ||
        ext === 'jpeg' ||
        ext === 'JPEG'
      ) {
        window.open(url, '_blank');
        return;
      }

      const link = document.createElement('a');
      link.href = url;
      link.download = file.filename;
      link.click();
    }
  }

  /**
   * Função que converte File em Binary
   * @param file objeto do tipo File a ser convertido
   */
  static async fileToBinary(file: File) {
    let fileBase64;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      fileBase64 = (reader.result as string).split(';base64,').pop();
    };
    return fileBase64;
  }

  /**
   * Função que converte File em Base64
   * @param file objeto do tipo File a ser convertido
   */
  static async fileToBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve((reader.result as string).split(';base64,').pop());
    });
  }

  static base64ToArrayBuffer(base64) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return byteArrays;
  }

  static objectToFile(obj) {
    return new File([obj.binary], obj.name, {
      type: obj.type,
      lastModified: obj.lastModify,
    });
  }

  static distinct(myArr, prop): any[] {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  static getUsuarioSession() {
    const usuLocal = localStorage.getItem(`${NAME_PROJECT}.usuario`);
    return usuLocal ? JSON.parse(atob(usuLocal)) : null;
  }

  static getUsuarioPerfil(): TipoPerfil {
    return Util.getUsuarioSession().perfil.toUpperCase();
  }


  static getAnoLetivo(){
    const anoLetivo = localStorage.getItem(`${NAME_PROJECT}.anoLetivo`);
    return anoLetivo ? JSON.parse(atob(anoLetivo)) : null;
  }

  static getUtb() {
    const utb = localStorage.getItem(`${NAME_PROJECT}.utb`);
    return utb ? JSON.parse(atob(utb)) : null;
  }

  static getDadosPeriodoAprovacaoSession() {
    const dadosPeriodoAprovacaoLocal = localStorage.getItem(
      `${NAME_PROJECT}.dadosPeriodoAprovacao`
    );
    return dadosPeriodoAprovacaoLocal
      ? JSON.parse(atob(dadosPeriodoAprovacaoLocal))
      : null;
  }

  static getListYear(): any[] {
    const yearToday = new Date().getFullYear();
    const range = [];
    range.push(yearToday);
    for (let i = 1; i < 12; i++) {
      range.push(yearToday - i);
    }
    return range;
  }

  static getRandomColor() {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  static toBoolean(param: any) {
    if (typeof param === 'boolean') {
      return param;
    } else {
      switch (param) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return param;
      }
    }
  }

  static downloadFromWeb(arquivo, fileDownloadName: string) {
    const downlodedFile = new Blob([arquivo], { type: 'application/pdf' });
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(downlodedFile);
    a.href = url;
    a.download = fileDownloadName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  static downloadFromLocal(fileBase64, fileDownloadName: string) {
    const sliceSize = 1024;
    const byteCharacters = atob(fileBase64);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    const downlodedFile = new Blob(byteArrays, { type: 'application/pdf' });
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(downlodedFile);
    a.href = url;
    a.download = fileDownloadName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  static downloadFileXlsx(response: any, name: string) {
    name = `${name}_${moment().format('DD-MM-yyyy')}.xlsx`.replace(/\s/g, '-');
    const blob = new Blob([response.body], {
      type: 'application/vnd.ms-excel;charset=utf-8',
    });
    saveAs(blob, name);
  }

  /**
   * Retorna o CPF ou CNPJ sem Máscara
   */
  static removeCpfCnpjMask(param: any) {
    return param.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
  }

  /**
   * @param cpfCnpjValue valor do cpj ou do cnpj
   * @returns máscara para cpf ou cnpj de acordo com a quantidade de caracteres
   */
  static setCpfOrCnpjMask(cpfCnpjValue: any) {
    if (cpfCnpjValue.length <= 11) {
      return '000.000.000-000';
    }

    return '00.000.000/0000-00';
  }

  static removeContatoMask(param: any) {
    return param
      .replaceAll(' ', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '');
  }

  /**
   * Função para retornar erro 403 ao usuário sem permissão acessar rota.
   * @param router Auxiliar para redirecionar as rotas
   * @param perfis Variável com array de um ou mais perfis
   */
  static redirectUnauthorizedUser(router: Router, perfis: any) {
    const usuario = this.getUsuarioSession();

    if (!perfis.some((x) => x === usuario.perfil)) {
      return router.navigate(['/sem-permissao']);
    }
  }

  /**
   * Função para desabilitar todos os campos do form.
   * @param resp variavel que vem os dados da requisição
   * @param formGroup variavel que traz o form group
   */
  static disableFieldsForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.controls[key].disable();
    });
  }

  /**
   * Funcão nativa do js isNullOrUndefined esta depreciada, então esse metodo o substituirá
   */
  static isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
  }

  /**
   *  Função que verifica se o usuário é Administrador
   */
  static isUserAdmin(): boolean {
    const usuario = this.getUsuarioSession();

    return usuario?.perfil.toUpperCase() === TipoPerfil.ADM;
  }

  /**
   *  Função que verifica se o usuário é Gestor Escolar
   */
  static isUserGE(): boolean {
    const usuario = this.getUsuarioSession();

    return usuario.perfil.toUpperCase() === TipoPerfil.GE;
  }

  /**
   *  Função que verifica se o usuário é Gerente Regional de Ensino (GRE)
   */
  static isUserGRE(): boolean {
    const usuario = this.getUsuarioSession();

    return usuario.perfil.toUpperCase() === TipoPerfil.GRE;
  }

  /**
   *  Função que verifica se o usuário é SEECT
   */
  static isUserSeect(): boolean {
    const usuario = this.getUsuarioSession();

    return usuario.perfil.toUpperCase() === TipoPerfil.SEECT;
  }

  static isEscolaEmAtividadeGE(): boolean {
    const usuario = this.getUsuarioSession();
    return usuario.escolaSituacao === 'EM_ATIVIDADE';
  }

  static patchValueFormReset(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.controls[key].patchValue('');
    });
  }

  /**
   * Função que converte uma string com o formato 'YYYY-MM-DD' em uma data
   * @param date data em string a ser convertida para o formato de data
   */

  static formatStringToDate(date: string) {
    return (
      date &&
      new Date(
        Number(date.slice(0, 4)),
        Number(date.slice(5, 7)) - 1,
        Number(date.slice(8, 10))
      )
    );
  }

  /**
   *  Função para listar anos
   */

  static getAnos(): Array<any> {
    const anosList = [];
    const anoAtual = new Date().getFullYear();

    for (let ano = anoAtual; ano <= anoAtual + 50; ano++) {
      anosList.push({
        name: ano,
        value: ano,
      });
    }

    return anosList;
  }

  /**
   * Função que recebe um vento do Automcomplete do primeng e retorna um parametro vazio nos
   * casos de clique no dropdown ou preenchido com o campo 'descrição' em caso de buscar por
   * digitação.
   * @param event Evento disparado no autocomplete ao digitar ou clicar no dropdown
   * @returns URLsearch param com o atributo descrição na pesquisa
   */
  static getAutocompleteParams(event) {
    const params = Util.createFilter();
    if (event?.query) {
      params.append('descricao', event.query);
    }
    return params;
  }

  /**
   * Verifica se há algum campo preenchido no objeto ou array
   * @param object objeto a ser verificado
   * @returns true caso haja algum valor preenchido, senão false
   */
  static objectHasAnyValue(object: any) {
    return Object.keys(object).some((key) => {
      const controlValue = object[key].value;
      return (
        typeof controlValue === 'boolean' ||
        (Array.isArray(controlValue)
          ? controlValue.length > 0
          : controlValue) ||
        controlValue?.trim()
      );
    });
  }

  static transformAutocompletes(object: any): any {
    const entity = {};
    Object.keys(object).forEach((key) => {
      const value = object[key];
      if (typeof value !== 'boolean' && value === '') {
        return;
      } // Não envia nos casos de não ser selecionado enum ou multiselect
      if (typeof value === 'object' && !Array.isArray(value)) {
        entity[key] = value?.id;
      } else if (Array.isArray(value)) {
        entity[key] = value.map(
          (valueChild) => valueChild.id || valueChild.value
        );
      } else {
        entity[key] = value;
      }
    });
    return entity;
  }

  /**
   * Ordena os cards de submenu por ordem alfabética
   * @param rotas lista de rotas para ser ordenada
   */
  static sortRoutes(rotas: Routes) {
    return rotas.sort((a: Route, b: Route) => {
      if (a.path > b.path) {
        return 1;
      } else if (a.path < b.path) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  static getDadosAlocacao(): any {
    const dadosHash = localStorage.getItem(`dadosAlocacao`);
    if (dadosHash) {
      return JSON.parse(atob(dadosHash));
    }

    return '';
  }

  static getDadosPessoais(): any {
    const dadosHash = localStorage.getItem(
      `${NAME_PROJECT}.dadosPessoaisProfessor`
    );
    if (dadosHash) {
      return JSON.parse(atob(dadosHash));
    }

    return '';
  }

  static getDadosProfissional(): any {
    const dadosHash = localStorage.getItem(
      `${NAME_PROJECT}.dadosMatriculaProfissional`
    );
    if (dadosHash) {
      return JSON.parse(atob(dadosHash));
    }

    return '';
  }

  /**
   * valida se o e-mail tem um formato de e-mail válido
   * @param email email a ser validado
   */
  static isEmailValid(email: string): boolean {
    const emailExpression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    return emailExpression.test(email);
  }

  static getHorarios(): Array<any> {
    const horariosList = [];

    for (let hora = 0; hora < 24; hora++) {
      for (let minutos = 0; minutos < 56; minutos += 5) {
        const data = moment();
        data.hour(hora);
        data.minute(minutos);

        horariosList.push({
          name: data.format('HH:mm'),
          value: data.format('HH:mm'),
        });
      }
    }
    return horariosList;
  }

  /**
   * Método para ordenar listas de objetos pelo nome.
   * @param
   * @returns
   */
  static sortByName(lista: any[]) {
    return lista.sort((a, b) => {
      if (a.nome > b.nome) {
        return 1;
      } else if (a.nome < b.nome) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  /**
   * Recupera os dados referente ao ano letivo selecionado após o login.
   */
  static getAnoLetivoInfo() {
    const anoLetivoInfo = localStorage.getItem('anoLetivoInfo');
    return JSON.parse(atob(anoLetivoInfo));
  }

  static formatNumberDate(numberDate: number) {
    return numberDate < 10 ? '0' + numberDate : numberDate;
  }

  static formatDateHours(date?: string): string {
    if (date) {
      const ano = date.substring(0, 4);
      const mes = date.substring(5, 7);
      const dia = date.substring(8, 10);

      const newDate = new Date(date);

      const horas = newDate.getHours();
      const minutos = newDate.getMinutes();

      return (
        dia +
        '/' +
        mes +
        '/' +
        ano +
        ' às ' +
        Util.formatNumberDate(horas) +
        ':' +
        Util.formatNumberDate(minutos)
      );
    }
  }

  static validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        this.validateAllFormFields(control);
      }
    });

    return formGroup.valid;
  }

  static checkIfAnyControlHasValue(formGroup: FormGroup): boolean {
    for (const controlName in formGroup.controls) {
      const control = formGroup.controls[controlName];
      if (control.value && control.value !== '') {
        return true;
      }
    }
    return false;
  }
  /**
   * @param fieldNames array com o nome dos formControls do formulário
   * @param form formulário a ser percorrido e ter seus campos desabilitados e removido validação
   */
  static desabilitarCamposValidacoes(fieldNames: string[], form: FormGroup) {
    const fields = fieldNames.map((fieldName) => form.get(fieldName));

    for (const field of fields) {
      field.disable();
      field.patchValue('');
      field.setValidators(null);
      field.updateValueAndValidity();
    }
  }

  /**
   * @param fieldNames array com o nome dos formControls do formulário
   * @param form formulário a ser percorrido e ter seus campos habilitador e adicionado validação obrigatória
   */
  static habilitarCamposValidacoes(fieldNames: string[], form: FormGroup) {
    const fields = fieldNames.map((fieldName) => form.get(fieldName));

    for (const field of fields) {
      field.enable();
      field.setValidators(Validators.required);
      field.updateValueAndValidity();
    }
  }

  /**
   * @param fieldNames array com o nome dos formControls do formulário
   * @param form formulário a ser percorrido e ter seus campos resetadosa
   */
  static resetarCampos(fieldNames: string[], form: FormGroup) {
    const fields = fieldNames.map((fieldName) => form.get(fieldName));

    for (const field of fields) {
      field.reset();
    }
  }

  static async getBase64FromFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve((reader.result as string)?.split(';base64,').pop() ?? '');
      reader.onerror = (error) => reject(error);
    });
  }

  static formatarCPF(cpf: string): string {
    if (cpf) {
      cpf = cpf.replace(/\D/g, '');
      cpf = cpf.padStart(11, '0');
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    return '-';
  }

  static convertEnumToArray(enumType): EnumBaseType[] {
    return Object.entries(enumType).map(([value, name]: [string, string]) => ({
      name,
      value,
    }));
  }

  static formatarNota(nota: any) {
    if (nota) {
      return nota.toFixed(2)?.replace('.', ',');
    }
  }

  static validMinAndMaxYearRoutes(routes: any[]) {
    const anoLetivo = Util.getAnoLetivo().ano;
    return routes.filter((menu) => {
      if (menu.data.minYear && Number(anoLetivo) < menu.data.minYear || menu.data.maxYear && Number(anoLetivo) > menu.data.maxYear) {
        return false;
      }
      return true;
    });
  }

  static addRemoveLoading() {
    const loading = document.getElementById('loading');
    if (loading.className === 'loading') {
      loading.classList.remove('loading');
    } else {
      loading.classList.add('loading');
    }
  }

  static mapearQuadroHorarioComponentesPorDia(dto: QuadroHorarioPdfDto) {
    const diasSemana = new DiaSemanaUtil().getAll();
    const tempos = new Tempo().getAll();

    const criarMapaComponentesPorDia = (tempo: any) => {
        return diasSemana.reduce((mapaDia, dia:any) => {
            const componente = dto.componentesQuadroListDto.find(
                (comp) =>
                    comp.diaSemana === dia.value &&
                    comp.tempo === tempo.value
            );
            mapaDia[dia.value] = componente?.componenteCurricular || '-';
            return mapaDia;
        },{} );
    };
    return tempos.reduce((mapaTempo, tempo:any) => {
        mapaTempo[tempo.value] = criarMapaComponentesPorDia(tempo);
        return mapaTempo;
    }, {} );
  }

  /** Gerar mensagens de erro para um formulário específico*/

  static getValidadeMessage(form, messages) {
    const validateMessage = new ValidateMessage();

    validateMessage.validationMessages = { ...messages }

    validateMessage.genericValidator = new GenericValidatorForm(
      validateMessage.validationMessages
    );

    validateMessage.messageDisplay = validateMessage.genericValidator.processMessages(form);
    return validateMessage;
  }
}
