import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { AutoCompleteModule, BreadcrumbModule, OverlayPanelModule, TableModule } from 'primeng';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { VerticalBarChartContentComponent } from './components/generic-charts/vertical-bar-chart-content/vertical-bar-chart-content.component';

import {
  RowExpansionTableComponent,
} from '../modules/escola/pages/escola-form/tab-formacao-turmas/tab-formacao-list-turmas/row-expansion-table/row-expansion-table.component';
import { AlertCustomComponent } from './components/alert-custom/alert-custom.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { CalendarioComponent } from './components/calendario/calendario.component';
import { CardMenuComponent } from './components/card-menu/card-menu.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { ShowColumnsIconComponent } from './components/data-table/show-columns-icon/show-columns-icon.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DragAndDropComponent } from './components/drag-and-drop/drag-and-drop.component';
import { FieldComponent } from './components/field/field.component';
import { FilterComponent } from './components/filter/filter.component';
import { FrequencyTableComponent } from './components/frequency-table/frequency-table.component';
import { AdvancedPieChartComponent } from './components/generic-charts/advanced-pie-chart/advanced-pie-chart.component';
import { FooterStackedChartComponent } from './components/generic-charts/footer-stacked-char/footer-stacked-chart.component';
import {
  NormalizedHorizontalBarChartComponent,
} from './components/generic-charts/normalized-horizontal-bar-chart/normalized-horizontal-bar-chart.component';
import { PieChartGraphComponent } from './components/generic-charts/pie-chart-graph/pie-chart-graph.component';
import { PieChartComponent } from './components/generic-charts/pie-chart/pie-chart.component';
import { GenericFormComponent } from './components/generics-crud-components/generic-form/generic-form.component';
import { ChangeColumnsFieldComponent } from './components/generics-crud-components/generic-list/change-columns-field/change-columns-field.component';
import {
  CustomColumnsDirective,
} from './components/generics-crud-components/generic-list/directives/custom-columns.directive';
import { GenericListComponent } from './components/generics-crud-components/generic-list/generic-list.component';
import { LoadingCustomInterceptor } from './components/loading-custom/config/loading-custom-interceptor';
import { LoadingCustomComponent } from './components/loading-custom/loading-custom.component';
import { ModalCustomComponent } from './components/modal-custom/modal-custom.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PaginatorCustomDinamicComponent } from './components/paginator-custom-dinamic/paginator-custom-dinamic.component';
import { PaginatorCustomComponent } from './components/paginator-custom/paginator-custom.component';
import { PickDayComponent } from './components/pick-day/pick-day.component';
import { PickMonthComponent } from './components/pick-month/pick-month.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { SideToggleButtonComponent } from './components/side-toggle-button/side-toggle-button.component';
import { StepsComponent } from './components/steps/steps.component';
import { TableBodyDirective } from './components/table-custom/config/table-body.directive';
import { TableEmptyDirective } from './components/table-custom/config/table-empty.directive';
import { TableHeaderDirective } from './components/table-custom/config/table-header.directive';
import { TableCustomComponent } from './components/table-custom/table-custom.component';
import { UploadButtonCustomComponent } from './components/upload-button-custom/upload-button-custom.component';
import { UploadButtonComponent } from './components/upload-button/upload-button.component';
import { UploadManyFilesComponent } from './components/upload-many-files/upload-many-files.component';
import { UploadOneFileComponent } from './components/upload-one-file/upload-one-file.component';
import { ApenasAlfaNumericoDirective } from './directives/apenas-alfanumerico.directive';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { DecimalInputDirective } from './directives/decimal-input.directive';
import { DisableOnClickDirective } from './directives/disable-on-click.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { UpperCaseDirective } from './directives/upper-case.directive';
import { CustomActionsDirective } from './components/generics-crud-components/generic-list/directives/custom-actions.directive';
import { LimitarValorInputDirective } from './directives/limitar-valor-input.directive';
import { GroupedVerticalBarChartComponent } from './components/generic-charts/grouped-vertical-bar-chart/grouped-vertical-bar-chart.component';
import { VerticalCustomBarComponent } from './components/generic-charts/vertical-custom-bar/vertical-custom-bar.component';
import { CustomBarChartLineaStatusComponent } from './components/generic-charts/vertical-custom-bar/custom-bar-chart-linea-status/custom-bar-chart-linea-status.component';
import { CustomBarChartSquaredStatusComponent } from './components/generic-charts/grouped-vertical-bar-chart/custom-bar-chart-squared-status/custom-bar-chart-squared-status.component';
import { SpeedDialComponent } from './components/speed-dial/speed-dial.component';

@NgModule({
  declarations: [
    // Componentes, diretivas e pipes
    AlertCustomComponent,
    LoadingCustomComponent,
    PaginatorCustomComponent,
    TableCustomComponent,
    TableHeaderDirective,
    TableBodyDirective,
    TableEmptyDirective,
    UpperCaseDirective,
    OnlyNumberDirective,
    DisableOnClickDirective,
    DebounceClickDirective,
    UploadOneFileComponent,
    UploadManyFilesComponent,
    PickMonthComponent,
    PickDayComponent,
    ModalCustomComponent,
    RowExpansionTableComponent,
    PageTitleComponent,
    GenericListComponent,
    GenericFormComponent,
    CustomColumnsDirective,
    FieldComponent,
    AutocompleteComponent,
    PieChartComponent,
    AdvancedPieChartComponent,
    FooterStackedChartComponent,
    NormalizedHorizontalBarChartComponent,
    DatePickerComponent,
    DragAndDropComponent,
    FilterComponent,
    CalendarioComponent,
    FrequencyTableComponent,
    VerticalBarChartContentComponent,
    CardMenuComponent,
    PieChartGraphComponent,
    UploadButtonCustomComponent,
    PaginatorCustomDinamicComponent,
    SideToggleButtonComponent,
    DecimalInputDirective,
    StepsComponent,
    RadioButtonComponent,
    UploadButtonComponent,
    ApenasAlfaNumericoDirective,
    CheckboxComponent,
    DataTableComponent,
    ShowColumnsIconComponent,
    ChangeColumnsFieldComponent,
    CustomActionsDirective,
    LimitarValorInputDirective,
    GroupedVerticalBarChartComponent,
    VerticalCustomBarComponent,
    CustomBarChartLineaStatusComponent,
    CustomBarChartSquaredStatusComponent,
    SpeedDialComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxChartsModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBorderRadius: '0px',
      fullScreenBackdrop: true,
      backdropBackgroundColour: 'rgba(0,0,0,0.3)',
      primaryColour: '#007bff',
      secondaryColour: '#0069d9',
      tertiaryColour: '#007bff',
    }),
    NgxMaskModule,
    NgxPaginationModule,
    AutoCompleteModule,
    BreadcrumbModule,
    CalendarModule,
    CardModule,
    CheckboxModule,
    DragDropModule,
    MultiSelectModule,
    OverlayPanelModule,
    ProgressBarModule,
    TableModule,
  ],
  exports: [
    AlertCustomComponent,
    LoadingCustomComponent,
    PaginatorCustomComponent,
    TableCustomComponent,
    TableHeaderDirective,
    TableBodyDirective,
    TableEmptyDirective,
    UpperCaseDirective,
    OnlyNumberDirective,
    DisableOnClickDirective,
    DebounceClickDirective,
    UploadOneFileComponent,
    UploadManyFilesComponent,
    PickMonthComponent,
    PickDayComponent,
    ModalCustomComponent,
    RowExpansionTableComponent,
    MultiSelectModule,
    CheckboxModule,
    CardModule,
    PageTitleComponent,
    GenericListComponent,
    GenericFormComponent,
    CustomColumnsDirective,
    FieldComponent,
    AutocompleteComponent,
    RadioButtonModule,
    PieChartComponent,
    AdvancedPieChartComponent,
    FooterStackedChartComponent,
    NormalizedHorizontalBarChartComponent,
    DatePickerComponent,
    DragAndDropComponent,
    FilterComponent,
    CalendarioComponent,
    FrequencyTableComponent,
    VerticalBarChartContentComponent,
    PieChartGraphComponent,
    DecimalInputDirective,
    StepsComponent,
    ApenasAlfaNumericoDirective,
    // Componentes Genéricos
    CardMenuComponent,
    UploadButtonCustomComponent,
    SideToggleButtonComponent,
    RadioButtonComponent,
    UploadButtonComponent,
    CheckboxComponent,
    DataTableComponent,
    ShowColumnsIconComponent,
    ChangeColumnsFieldComponent,
    CustomActionsDirective,
    LimitarValorInputDirective,
    GroupedVerticalBarChartComponent,
    VerticalCustomBarComponent,
    CustomBarChartLineaStatusComponent,
    CustomBarChartSquaredStatusComponent,
    SpeedDialComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingCustomInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
