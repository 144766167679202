<footer>
  <div class="container h-100">
    <div class="row justify-content-between align-items-center h-100 w-100">
      <span>{{ anoAtual }} | Governo da Paraíba</span>

      <div class="m-auto" *ngIf="ambienteQA">
        <h6 class="info-teste">
          {{usuarioSession.nome}}, você está no ambiente de HOMOLOGAÇÃO/TREINAMENTO.
        </h6>
      </div>

      <a routerLink="/">
        <img src="assets/img/siage_logo_05.png" alt="logo saber" class="logo" />
      </a>

    </div>
  </div>
</footer>
