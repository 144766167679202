import { Guid } from 'guid-typescript';
import { AutocompleteDto } from '../autocomplete.dto';

export class NotificacaoListDto {

  usuarioId: Guid;
  usuarioSolicitanteNome: string;
  id: Guid;
  mensagem: string;
  tipo: string;
  dataCriacao: Date;
  visualizada: boolean;
  entidadeRelacionada: AutocompleteDto;

  constructor(object?: any) {
    if (object) {
      this.usuarioId                     = object.usuarioId;
      this.usuarioSolicitanteNome        = object.usuarioSolicitanteNome;
      this.id                            = object.id;
      this.mensagem                      = object.mensagem;
      this.tipo                          = object.tipo;
      this.dataCriacao                   = object.dataCriacao;
      this.visualizada                   = object.visualizada;
      this.entidadeRelacionada           = object.entidadeRelacionada;
    }
  }
}
