
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MSG_FALIELD } from 'src/app/shared/utils/constants';
import { SweetalertCustom } from 'src/app/shared/utils/sweetalert-custom';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((httpErrorResponse) => {
                    if (httpErrorResponse.error instanceof ErrorEvent) {
                        // Este é um erro do lado do cliente.
                        SweetalertCustom.showAlertConfirm('error', MSG_FALIELD, 'Contate o administrador do sistema.');
                    } else {
                        // Este é um erro do lado do servidor.
                        if (httpErrorResponse instanceof HttpErrorResponse) {
                            if (httpErrorResponse.status === 401) {
                                this.router.navigate(['/auth/logout']);
                            } else if (httpErrorResponse.status === 403) {
                              this.router.navigate(['sem-permissao']);
                              return;
                            }
                        }
                        if (httpErrorResponse.error?.errors) {
                            SweetalertCustom.showAlertConfirm('error', httpErrorResponse.error.errors);
                        } else {
                            SweetalertCustom.showAlertConfirm('error', MSG_FALIELD);
                        }
                    }
                    return throwError(httpErrorResponse.error);
                })
            );
    }
}
