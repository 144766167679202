import { MeusDadosModule } from './modules/meus-dados/meus-dados.module';
import { Routes } from '@angular/router';
import { AllDatasGuard } from './core/guards/all-datas.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { PaginaSemAutorizacaoComponent } from './core/layouts/componetes/pagina-sem-autorizacao/pagina-sem-autorizacao.component';
import { LayoutAuthComponent } from './core/layouts/layout-auth/layout-auth.component';
import { LayoutNoAuthComponent } from './core/layouts/layout-no-auth/layout-no-auth.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: LayoutAuthComponent,
    canActivate: [AuthGuard, AllDatasGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
        data: { breadcrumb: null },
      },
      {
        path: 'escolas',
        loadChildren: () =>
          import('./modules/escola/escola.module').then((m) => m.EscolaModule),
        data: { breadcrumb: 'Escolas' },
      },
      {
        path: 'meus-alunos',
        loadChildren: () =>
          import('./modules/meus-alunos/meus-alunos.module').then((m) => m.MeusAlunosModule),
        data: { breadcrumb: 'Meus Alunos' },
      },
      {
        path: 'meus-estudantes',
        loadChildren: () =>
          import('./modules/meus-estudantes/meus-estudantes.module').then((m) => m.MeusEstudantesModule),
        data: { breadcrumb: 'Meus Estudantes' },
      },
      {
        path: 'quadro-funcional',
        loadChildren: () =>
          import('./modules/quadro-funcional/quadro-funcional.module').then((m) => m.QuadroFuncionalModule),
        data: { breadcrumb: 'Quadro Funcional'},
      },
      {
        path: 'ajuda',
        loadChildren: () =>
          import('./modules/ajuda/ajuda.module').then((m) => m.AjudaModule),
        data: { breadcrumb: 'Ajuda' },
      },
      {
        path: 'minhas-turmas',
        loadChildren: () =>
          import('./modules/minhas-turmas/minhas-turmas.module').then(
            (m) => m.MinhasTurmasModule
          ),
        data: { breadcrumb: 'Minhas Turmas' },
      },
      {
        path: 'pre-matricula',
        loadChildren: () =>
          import('./modules/pre-matricula/pre-matricula.module').then(
            (m) => m.PreMatriculaModule
          ),
        data: { breadcrumb: 'Pré-Matrícula' },
      },
      {
        path: 'matricula',
        loadChildren: () =>
          import('./modules/matricula-estudante/matricula-estudante.module').then(
            (m) => m.MatriculaEstudanteModule
          ),
        data: { breadcrumb: 'Matrícula' },
      },
      {
        path: 'formacao-turmas',
        loadChildren: () =>
          import('./modules/formacao-turma/formacao-turma.module').then(
            (m) => m.FormacaoTurmaModule
          ),
        data: { breadcrumb: 'Processo de Matrículas' },
      },
      {
        path: 'notificacoes',
        loadChildren: () =>
          import('./modules/notificacao/notificacao.module').then(
            (m) => m.NotificacaoModule
          ),
        data: { breadcrumb: 'Notificações' },
      },
      {
        path: 'painel-monitoramento',
        loadChildren: () =>
          import(
            './modules/painel-monitoramento/painel-monitoramento.module'
          ).then((m) => m.PainelMonitoramentoModule),
        data: { breadcrumb: 'Painel de Monitoramento' },
      },
      {
        path: 'sem-permissao',
        component: PaginaSemAutorizacaoComponent,
      },
      {
        path: 'configuracao',
        loadChildren: () =>
          import('./modules/configuracao/configuracao.module').then(
            (m) => m.ConfiguracaoModule
          ),
        data: { breadcrumb: 'Configuração' },
      },
      {
        path: 'relatorios',
        loadChildren: () =>
          import('./modules/relatorios/relatorios.module').then(
            (m) => m.RelatoriosModule
          ),
        data: { breadcrumb: 'Relatórios' },
      },
      {
        path: 'curriculo',
        loadChildren: () =>
          import('./modules/curriculo/curriculo.module').then(
            (m) => m.CurriculoModule
          ),
        data: { breadcrumb: 'Currículo' },
      },
      {
        path: 'matriculas',
        loadChildren: () =>
          import('./modules/matricula/matricula.module').then(
            (m) => m.MatriculaModule
          ),
        data: { breadcrumb: 'Criação de Turmas' },
      },
      {
        path: 'status-desempenho-final',
        loadChildren: () =>
          import('./modules/processo-aprovacao/processo-aprovacao.module').then(
            (m) => m.ProcessoAprovacaoModule
          ),
        data: { breadcrumb: 'Desempenho Final' },
      },
      {
        path: 'calendario',
        loadChildren: () =>
          import('./modules/calendario-escolar/calendario-escolar.module').then(
            (m) => m.CalendarioEscolaModule
          ),
        data: { breadcrumb: 'Calendário' },
      },
      {
        path: 'enem',
        loadChildren: () =>
          import('./modules/enem/enem.module').then(
            (m) => m.EnemModule
          ),
        data: { breadcrumb: 'ENEM' },
      },
      {
        path: 'meus-dados',
        loadChildren: () =>
          import('./modules/meus-dados/meus-dados.module').then(
            (m) => m.MeusDadosModule
          ),
        data: { breadcrumb: 'Atualização de Informações Pessoais' },
      },
    ],
  },
  {
    path: 'auth',
    component: LayoutNoAuthComponent,
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  }
];
